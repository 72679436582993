import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Monitor your competitors"
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">

            <p>The digital world moves fast, and change can come in what seems like an instant.
              With this in mind, it is essential to stay one step ahead by knowing what your competition and market is doing. </p>
            <p>
              Of course, having to regularly visit these websites and scroll through them requires time that would be better spent
              on growing your own business. Hexowatch takes the hassle out of competitor monitoring by periodically checking your
              competitors websites and industry portals notifying you when changes occur.
            </p>
            <p>
              Hexowatch allows you to tailor how you monitor the competition, which allows you to be the first to get the information that matters most to you.
              You can track changes to the feature set of your competition, monitor a keyword or a key phrase, monitor prices or news relevant to
              your brand.
            </p>

            <h4>Keep on top of new products</h4>
            <p>Use Hexowatch to get a notification about new press releases, when the competition adds a new product to a specific category,
              or when competitors make changes to an existing pricing or product page.</p>

            <h4>Price changes</h4>
            <p>Keep your pricing competitive by monitoring your competitors prices and receive alerts when prices go up or down. </p>

            <h4>New hires</h4>
            <p>Find out if the competition has filled an important position with a new hire, or if they have convinced a talented executive to join from another company.</p>

            <h4>Consumer Feedback and Complaints</h4>
            <p>Competitor monitoring doesn’t end on their website, you can also be alerted about your competitor’s shortcomings
               by monitoring public forums and review websites. </p>

            <h4>Go beyond competitor monitoring</h4>
            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>

            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, 
              keep an archive of every change and turn any website into your own private data source accessing changes as a 
              downloadable CSV file, google sheets, or via Zapier.</p>
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
